import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import { toast } from "react-toastify";

// Your web app's Firebase configuration
const ENV = process.env;
const firebaseConfig = {
  apiKey: ENV.REACT_APP_FIREBASE_API_KEY,
  authDomain: ENV.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: ENV.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: ENV.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: ENV.REACT_APP_FIREBASE_MESSAGEING_SENDER_ID,
  appId: ENV.REACT_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getTokenData = () => {
  return getToken(messaging, { vapidKey: ENV.REACT_APP_FIREBASE_VALID_KEY })
    .then(currentToken => {
      return currentToken;
    })
    .catch(err => {
      console.log("An error occurred while retrieving token. ", err);
      return null;
    });
};

//foreground message
onMessage(messaging, payload => {
  if (payload) showNotification(payload);
});

const showNotification = payload => {
  const audio = new Audio("/ningnong.mp3");
  audio.play();

  toast.info(`${payload?.data?.title} | ${payload?.data?.body}`, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
