import { createStore, combineReducers } from "redux";

import userInfo_Reducer from "./reducers/userInfo_Reducer";
import sideBarShow_Reducer from "./reducers/sideBarShow_Reducer";

const rootReducer = combineReducers({
  userInfoStore: userInfo_Reducer,
  sideBarShowStore: sideBarShow_Reducer,
});

const configureStore = () => {
  return createStore(rootReducer);
};

export default configureStore;
