import { ADD_UDATA } from "../actions/actionsTyprs";

const initialState = {
  user_info: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_UDATA:
      return {
        user_info: action.uData,
      };
    default:
      return state;
  }
};

export default reducer;
