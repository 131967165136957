import CryptoJS from "crypto-js";
const env = process.env;

export function encrypt(text_to_encrypt) {
  if (!text_to_encrypt) return null;

  const ciphertext = CryptoJS.AES.encrypt(text_to_encrypt, env.REACT_APP_API_KEY_TOKEN).toString();
  return ciphertext;
}

export function decrypt(text_to_decrypt) {
  if (!text_to_decrypt) return null;

  const bytes = CryptoJS.AES.decrypt(text_to_decrypt, env.REACT_APP_API_KEY_TOKEN);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
}
