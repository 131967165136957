import axios from "axios";
import { BASE_URL } from "../../api";
import { decrypt, encrypt } from "../crypto";

// REDUX
import configureStore from "../../redux/configureStore";
import { addUdata } from "../../redux/actions";

const store = configureStore();
const { dispatch } = store;
const _AddUserInfo = info => dispatch(addUdata(info));

// AXIOS
const axi_gbl = axios.create({
  baseURL: BASE_URL,
  timeout: 60 * 1000,
});

/***** || --==-- || EDIT BY PINKKY || --==-- ||
|------------------------------------------------------------------------------------------------------------------------------------
| |==| REQUEST TOKEN |==|
|------------------------------------------------------------------------------------------------------------------------------------
*****/

const isHandlerEnabled = (config = {}) => {
  return config.hasOwnProperty("handlerEnabled") && !config.handlerEnabled ? false : true;
};

axi_gbl.interceptors.request.use(request => requestHandler(request));

const requestHandler = async request => {
  if (isHandlerEnabled(request)) {
    const access_token = decrypt(localStorage.getItem("Access_Token"));
    const refresh_token = decrypt(localStorage.getItem("Refresh_Token"));

    // Modify request here
    if (!!access_token && !!refresh_token) {
      request.headers.access_token = `Bearer ${access_token}`;
      request.headers.refresh_token = `Bearer ${refresh_token}`;
    }
  }
  return request;
};

// /***** || --==-- || EDIT BY PINKKY || --==-- ||
// |------------------------------------------------------------------------------------------------------------------------------------
// | |==| RESPONSE TOKEN |==|
// |------------------------------------------------------------------------------------------------------------------------------------
// *****/

axi_gbl.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error),
);

const successHandler = async response => {
  if (isHandlerEnabled(response.config)) {
    // Handle responses
    const header_token = response.headers;
    const { access_token, refresh_token } = header_token;
    if (!!access_token && !!refresh_token) {
      localStorage.setItem("Access_Token", encrypt(access_token));
      localStorage.setItem("Refresh_Token", encrypt(refresh_token));
    }
  }
  return response;
};

const errorHandler = async error => {
  if (isHandlerEnabled(error.config)) {
    if (error.response.status === 403) {
      localStorage.clear();
      _AddUserInfo(null);
      window.location.reload();
    }
  }
  return Promise.reject({ ...error });
};

export default axi_gbl;
