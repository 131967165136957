import { ADD_SIDE_BAR_SHOW } from "../actions/actionsTyprs";

const initialState = {
  sidebarShow: "responsive",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SIDE_BAR_SHOW:
      return {
        sidebarShow: action.dtSBS,
      };
    default:
      return state;
  }
};

export default reducer;
